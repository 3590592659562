import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const TermsPage = () => (
    <Layout>
        <SEO title="Mentions Légales"/>
        <div className={"container"}>
            <div className={"content"}>
                <div className={"title"}>
                    <h1>Mentions légales</h1>
                </div>



                <h2>1. Éditeur du site internet</h2>
                    <p>
                        La Site internet "VIVR" accessible à l'adresse URL "<a href="https://vivr.co">https://vivr.co</a>" (le "Site Internet") est édité par la société par actions simplifiée au capital de 1000 euros, dont le siège est situé au 22 rue de la fontaine au roi, 75011 PARIS 
                        et immatriculée au registre du commerce et des sociétés de Paris sous le n° 884 428 210, et représentée par son représentant légal Monsieur BEN RABAH Mohamed-Ali. 
                    </p>
                    <p>Contact:<a href="mailto:contact@vivr.co"> contact@vivr.co</a> </p>




                <h2>2. Hébergement de l’application</h2>
                    <p>
                    Le Site internet est hébergé par Google Cloud Platform, 8 rue de Londres 75009 Paris. Google Cloud Platform dispose de Data-Centers hautement sécurisés situés en Europe, offrant d'importantes garanties de sécurité et respecte la législation européenne concernant les données personnelles.<br/> 
                    Pour en savoir plus sur la sécurité mise en œuvre : <a href="https://cloud.google.com/security">https://cloud.google.com/security</a>.
                        
                    </p>


                <h2>3. Directeur de publication</h2>    
                        <p>
                            Le directeur de la publication de l’Application VIVR est Monsieur BEN RABAH Mohamed-Ali
                        </p>
                

                      


                
            </div>
        </div>
    </Layout>
)

export default TermsPage
